<template>
  <transition name="fade" mode="out-in">
    <img :src="currentSrc" :key="currentSrc" :class="className" :alt="alt" />
  </transition>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true
    },
    className: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      currentSrc: this.src
    };
  },
  watch: {
    src(newSrc) {
      this.currentSrc = newSrc;
    }
  }
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
